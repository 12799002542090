import { stringToNumber } from '@/utils/typeUtils.js';

// TODO: 찜하기 스토어 전체 손보기
export const state = () => ({
  // 상품 알람 설정 여부
  id: 0,
  isOnAlarm: false,
  productId: 0,
  // 내가 찜한 상품 리스트
  favoriteProductList: [],
  // 내가 찜한 로드맵 리스트
  favoriteCourseList: [],
  // 내가 찜한 상품 ID 리스트
  favoriteProductIds: [],
  // 내가 찜한 로드맵 ID 리스트
  favoriteCourseIds: [],
  // 이 수업을 찜하거나 알람설정 했는지
  favoriteState: {
    productId: -1,
    isFavorite: false, // 찜 여부
    isOnAlarm: false, // 알림신청 여부
  },
});

export const getters = {
  favoriteState: state => state.favoriteState,
  favoriteProduct: state => {
    return {
      id: state.id,
      productId: state.productId,
      isOnAlarm: state.isOnAlarm,
    };
  },
  favoriteProductList: state =>
    state.favoriteProductList.filter(f => f.productId !== null),
  favoriteCourseList: state =>
    state.favoriteCourseList.filter(f => f.id !== null), // TODO: 찜한 로드맵 리스트 추가
  favoriteProductIds: state => state.favoriteProductIds,
  favoriteCourseIds: state => state.favoriteCourseIds,
};

export const actions = {
  async selectFavoriteList({ commit }, { page, size }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/user/favorite/paging`,
        {
          params: { page, size },
        },
      );

      // 첫 페이지면 초기화
      if (page === 1) commit('RESET_FAVORITE_PRODUCTS');

      const vodIdListResponse = await this.$axios.$get(`${process.env.API_V3}/v3/order/vod/class-id-only`);

      commit(
        'SET_FAVORITE_PRODUCTS',
        data.items.map(item => {
          return {
            ...item,
            product: {
              id: item.classId,
              title: item.classTitle,
              thumbnailImg: item.classThumbnailImg,
              classType: item.classType,
              classAge: `${item.minAge}~${item.maxAge}세`,
              teacher: {
                id: item.teacherId,
                name: item.teacherName,
                profileImg: item.teacherProfileImg,
              },
              favoriteCount: item.favoriteCount,
              rating: `${item.reviewRating}`,
              ratingCount: item.reviewCount,
              hasSchedule: item.hasSchedule,
              productStatus: item.productStatus,
              isVod: vodIdListResponse.data.includes(item.classId),
            },
          };
        }),
      );

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 찜한 로드맵 리스트 받아오는 api
   */
  async selectFavoriteCourseList({ commit, dispatch }, { page, size }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/my/favorite-course`,
        {
          params: { page, size },
        },
      );

      // course의 progress를 따로 받아와서 넣어줍니다.
      const progress =
        data.items.length > 0
          ? await dispatch(
              'course/getCourseProgress',
              {
                courseIds: data.items.map(course => course.id),
              },
              { root: true },
            )
          : [];

      const courseList = data.items.map(course => ({
        ...course,
        progress: progress.find(p => p.courseId === course.id)?.progress || -1,
      }));

      // 첫 페이지면 초기화
      if (data.currentPage === 1) commit('RESET_FAVORITE_COURSE');
      commit('SET_FAVORITE_COURSE', courseList);

      return data;
    } catch (e) {
      return [];
    }
  },
  /**
   * TODO: 신규 상품 페이지로 넘어가면 지울것
   * 확인사항
   * 1. payment page에서도 지울것
   */
  // 단일 product 찜하기 & 알람설정 상태 가져오기
  selectProductFavoriteStatus({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/favorite/${id}`)
        .then(res => {
          res && res.productId && commit('SET_FAVORITE_STATUS', res);
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 찜한 목록 배열로 받아오기
  selectFavoriteProductIds({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/favorite/classIdOnly`)
        .then(res => {
          commit('SET_FAVORITE_PRODUCTS_IDS', res.data);
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  selectFavoriteCourseIds({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/class/my/favorite-course/ids`)
        .then(res => {
          commit('SET_FAVORITE_COURSE_IDS', res.data);
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  /**
   * TODO: 신규 상품 페이지로 넘어가면 지울것
   * 확인사항
   * 1. 상품 컴포넌트와 그 외 favorite button 등등에서 쓰이는 액션 다 변경해야함
   */
  // 찜하기 토글
  toggleFavorite({ commit }, { productId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/favorite`, { command: 'favorite', productId })
        .then(res => {
          if (res.isFavorite) {
            commit('ADD_FAVORITE', { productId });
          } else {
            commit('REMOVE_FAVORITE', { productId });
          }

          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * TODO: 신규 상품 페이지로 넘어가면 지울것
   * 확인사항
   * 1. 상품 컴포넌트와 그 외 favorite button 등등에서 쓰이는 액션 다 변경해야함
   */
  // 알람 토글
  toggleAlarm({ commit }, { productId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/favorite`, { command: 'alarm', productId })
        .then(res => {
          if (res.isOnAlarm) {
            commit('ADD_ALARM', { classId: productId });
          } else {
            commit('REMOVE_ALARM', { classId: productId });
          }
          resolve(res);
        })
        .catch(reject);
    });
  },

  /**
   * @description
   * 알람 신청 토글
   * @returns {number} favoriteCount 이 수업의 찜하기 전체 수
   * @returns {boolean} isFavorite 찜한 상태인가
   * @returns {boolean} isOnAlarm 알람신청 상태인가
   */
  async updateToggleAlarm({ commit }, { productId }) {
    try {
      const res = await this.$axios.$post(`/v2/user/favorite`, {
        command: 'alarm',
        productId,
      });

      commit(res.isOnAlarm ? 'ADD_ALARM' : 'REMOVE_ALARM', {
        classId: productId,
      });
      commit('UPDATE_FAVORITE_STATE', {
        productId,
        isFavorite: !!res,
        isOnAlarm: !!res && res.isOnAlarm,
      });

      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  /**
   * @description
   * 찜하기 토글
   * @returns {number} favoriteCount 이 수업의 찜하기 전체 수
   * @returns {boolean} isFavorite 찜한 상태인가
   * @returns {boolean} isOnAlarm 알람신청 상태인가
   */
  async toggleProductFavorite({ commit }, { productId }) {
    try {
      const res = await this.$axios.$post(`/v2/user/favorite`, {
        command: 'favorite',
        productId,
      });

      commit(res.isFavorite ? 'ADD_FAVORITE' : 'REMOVE_FAVORITE', {
        productId,
      });

      commit('UPDATE_FAVORITE_STATE', {
        productId,
        isFavorite: !!res,
        isOnAlarm: !!res && res.isOnAlarm,
      });

      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  /**
   * @description
   * 이 product의 찜하기 & 알람설정 상태를 가져와 스토어에 넣어주는 api
   */
  async getProductFavoriteState({ commit }, { productId }) {
    try {
      const res = await this.$axios.$get(`/v2/user/favorite/${productId}`);

      // 찜하기가 안되어있는 아이템이라면 빈 값으로 옵니다
      commit('SET_FAVORITE_STATE', {
        isFavorite: !!res,
        isOnAlarm: !!res && res.isOnAlarm,
        productId,
      });
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * @description
   * 로드맵 찜하기 api
   */
  async createCourseFavorite({ commit }, { courseId }) {
    try {
      await this.$axios.$post(
        `${process.env.API_V3}/v3/class/course/${courseId}/course-favorites`,
      );
      commit('ADD_FAVORITE_COURSE', { courseId });
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * @description
   * 로드맵 찜하기 취소 api
   */
  async deleteCourseFavorite({ commit }, { courseId }) {
    try {
      await this.$axios.$delete(
        `${process.env.API_V3}/v3/class/course/${courseId}/course-favorites`,
      );
      commit('REMOVE_FAVORITE_COURSE', { courseId });
    } catch (e) {
      console.error(e);
    }
  },
};

export const mutations = {
  SET_FAVORITE_STATE(state, { isFavorite, isOnAlarm, productId }) {
    state.favoriteState = {
      productId,
      isFavorite,
      isOnAlarm,
    };
  },
  UPDATE_FAVORITE_STATE(state, { isFavorite, isOnAlarm, productId }) {
    // 같은 프로덕트 id일때만 업데이트 해줍니다.
    if (
      stringToNumber(state.favoriteState.productId) ===
      stringToNumber(productId)
    ) {
      state.favoriteState = {
        productId,
        isFavorite,
        isOnAlarm,
      };
    }
  },
  SET_FAVORITE_STATUS(state, { id, isOnAlarm, productId }) {
    state.id = id;
    state.isOnAlarm = isOnAlarm;
    state.productId = productId;
  },
  RESET_FAVORITE_STATUS(state) {
    state.id = 0;
    state.isOnAlarm = false;
    state.productId = 0;
  },
  RESET_FAVORITE_PRODUCTS(state) {
    state.favoriteProductList = [];
  },
  SET_FAVORITE_PRODUCTS(state, favoriteProductList) {
    // 중복제거해줍니다.
    const favoriteClassIdList = state.favoriteProductList.map(p => p.classId);
    const favoriteClassList = favoriteProductList.filter(
      p => !favoriteClassIdList.includes(p.classId),
    );
    state.favoriteProductList.push(...favoriteClassList);
  },
  RESET_FAVORITE_COURSE(state) {
    state.favoriteCourseList = [];
  },
  SET_FAVORITE_COURSE(state, favoriteCourseList) {
    state.favoriteCourseList.push(...favoriteCourseList);
  },
  SET_FAVORITE_PRODUCTS_IDS(state, favoriteProductIds) {
    state.favoriteProductIds = favoriteProductIds;
  },
  SET_FAVORITE_COURSE_IDS(state, favoriteCourseIds) {
    state.favoriteCourseIds = favoriteCourseIds;
  },
  ADD_FAVORITE(state, { productId }) {
    state.favoriteProductIds.push(productId);
  },
  REMOVE_FAVORITE(state, { productId }) {
    state.isOnAlarm = false;
    state.favoriteProductIds = state.favoriteProductIds.filter(
      id => id !== productId,
    );
  },
  ADD_FAVORITE_COURSE(state, { courseId }) {
    state.favoriteCourseIds.push(courseId);
  },
  REMOVE_FAVORITE_COURSE(state, { courseId }) {
    state.favoriteCourseIds = state.favoriteCourseIds.filter(
      id => id !== courseId,
    );
  },
  // 찜한 로드맵 추가 | 삭제
  ADD_FAVORITE_COURSE_PRODUCT(state, { course, courseId }) {
    // 혹시 모르는 중복 제거 후 추가
    state.favoriteCourseList = state.favoriteCourseList.filter(
      course => course.id !== courseId,
    );
    state.favoriteCourseList.unshift(course);
  },
  REMOVE_FAVORITE_COURSE_PRODUCT(state, { courseId }) {
    state.favoriteCourseList = state.favoriteCourseList.filter(
      course => course.id !== courseId,
    );
  },
  ADD_ALARM(state, { classId }) {
    state.isOnAlarm = true;
    // 추가할 때 찜한 수업 리스트에 없으면 다시 복구시켜줍니다.
    state.favoriteProductIds = state.favoriteProductIds
      .filter(i => i !== classId)
      .concat(classId);

    state.favoriteProductList = state.favoriteProductList.map(favorite => {
      if (favorite.classId === classId) return { ...favorite, isOnAlarm: true };
      return favorite;
    });
  },
  REMOVE_ALARM(state, { classId }) {
    state.isOnAlarm = false;

    state.favoriteProductList = state.favoriteProductList.map(favorite => {
      if (favorite.classId === classId)
        return { ...favorite, isOnAlarm: false };
      return favorite;
    });
  },
  ADD_FAVORITE_PRODUCT(state, { product, classId, isOnAlarm = false }) {
    // 혹시 모르는 중복 제거 후 추가
    state.favoriteProductList = state.favoriteProductList.filter(
      product => product.classId !== classId,
    );
    state.favoriteProductList.unshift({ product, classId, isOnAlarm }); // 찜 한 상품의 데이터 구조와 맞춰주기
  },
  REMOVE_FAVORITE_PRODUCT(state, { classId }) {
    state.favoriteProductList = state.favoriteProductList.filter(
      product => product.classId !== classId,
    );
  },
};
