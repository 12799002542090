import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _177b3464 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _6332ec9a = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _032dc419 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _3dfaa5ee = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _35e7c019 = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _729e28e4 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3dee6bae = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _1a63bb4c = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _72318152 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _4d6ae07a = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _709e4062 = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _161a8b0c = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _2bf73b6c = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _90f96240 = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _14da98d0 = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _fb7484ce = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _35e8ad2a = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _b6c28a14 = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _32878362 = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _b533a0f6 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _527911a0 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _67c4899b = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _118741ce = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _34959566 = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _768cb670 = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _86a95068 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _7a4ce6fa = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _767614f6 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _82a59c1e = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _05c95e1f = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _088b29a6 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _62201270 = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _54e70a8c = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _91d9b598 = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _d1c5152c = () => interopDefault(import('../pages/my/vod/index.vue' /* webpackChunkName: "pages/my/vod/index" */))
const _17d0428a = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _b90d99f4 = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _7a208ef6 = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _a2cb8712 = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _51f8a3da = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _6f4abf44 = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _e01a37b8 = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _05ea9d3b = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _1670a636 = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _44d23b35 = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _1ba89bde = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _790805c7 = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _ca110598 = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _82e6773e = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _6add2846 = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _67645f20 = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _96795666 = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _08380f66 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _ee14aa1c = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _0d34e3dc = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _4c5feb0b = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _4246b1a2 = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _e48c59ce = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _8130b43a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67d5a8a5 = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _cb0fc702 = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _1f15de6e = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _7751647f = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _46e1655a = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _ccad7ac2 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _15c4931c = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _3c4404a6 = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _197012ae = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _f756197c = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _d2a0b22a = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _f5ffdda4 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _4f5e813c = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _4e78c7a6 = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _16885662 = () => interopDefault(import('../pages/product/_id/notice/_noticeId.vue' /* webpackChunkName: "pages/product/_id/notice/_noticeId" */))
const _740b82f2 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _8e4ee190 = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _0fca688a = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _7ebbed98 = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _b0f36488 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _b929fa44 = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _bef3bbae = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _177b3464,
    name: "blog"
  }, {
    path: "/chat",
    component: _6332ec9a,
    name: "chat"
  }, {
    path: "/error",
    component: _032dc419,
    name: "error"
  }, {
    path: "/event",
    component: _3dfaa5ee,
    name: "event"
  }, {
    path: "/introduction",
    component: _35e7c019,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _729e28e4,
    name: "maintenance"
  }, {
    path: "/my",
    component: _3dee6bae,
    name: "my"
  }, {
    path: "/notice",
    component: _1a63bb4c,
    name: "notice"
  }, {
    path: "/private",
    component: _72318152,
    name: "private"
  }, {
    path: "/product",
    component: _4d6ae07a,
    name: "product"
  }, {
    path: "/psych",
    component: _709e4062,
    name: "psych"
  }, {
    path: "/psyche",
    component: _161a8b0c,
    name: "psyche"
  }, {
    path: "/ranking",
    component: _2bf73b6c,
    name: "ranking"
  }, {
    path: "/request",
    component: _90f96240,
    name: "request"
  }, {
    path: "/roadmap",
    component: _14da98d0,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _fb7484ce,
    name: "schedule"
  }, {
    path: "/search",
    component: _35e8ad2a,
    name: "search"
  }, {
    path: "/teacher",
    component: _b6c28a14,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _32878362,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _b533a0f6,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _527911a0,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _67c4899b,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _118741ce,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _34959566,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _768cb670,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _86a95068,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _7a4ce6fa,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _767614f6,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _82a59c1e,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _05c95e1f,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _088b29a6,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _62201270,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _54e70a8c,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _91d9b598,
    name: "my-subscription"
  }, {
    path: "/my/vod",
    component: _d1c5152c,
    name: "my-vod"
  }, {
    path: "/my/voucher",
    component: _17d0428a,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _b90d99f4,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _7a208ef6,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _a2cb8712,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _51f8a3da,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _6f4abf44,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _e01a37b8,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _05ea9d3b,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _1670a636,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _44d23b35,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _1ba89bde,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _790805c7,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _ca110598,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _82e6773e,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _6add2846,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _67645f20,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _96795666,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _08380f66,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _ee14aa1c,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _0d34e3dc,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _4c5feb0b,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _4246b1a2,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _e48c59ce,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _8130b43a,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _67d5a8a5,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _cb0fc702,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _1f15de6e,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _7751647f,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _46e1655a,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _ccad7ac2,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _15c4931c,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _3c4404a6,
    children: [{
      path: "",
      component: _197012ae,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _f756197c,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _d2a0b22a,
    children: [{
      path: "",
      component: _f5ffdda4,
      name: "product-id"
    }, {
      path: "payment",
      component: _4f5e813c,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _4e78c7a6,
      name: "product-id-subscribe"
    }, {
      path: "notice/:noticeId?",
      component: _16885662,
      name: "product-id-notice-noticeId"
    }]
  }, {
    path: "/psych/:slug",
    component: _740b82f2,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _8e4ee190,
    children: [{
      path: "",
      component: _0fca688a,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _7ebbed98,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _b0f36488,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _b929fa44,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _bef3bbae,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
