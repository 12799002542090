import { endOfMonth, startOfDay, startOfMonth } from 'date-fns';

const initState = {
  scheduleListByDates: [],
  startDate: null, // 현재 state.scheduleList 의 시작 시점. yyyy-MM-dd
  endDate: null, // 현재 state.scheduleList 의 종료 시점. yyyy-MM-dd
  todayDate: null,
  isPastCalendar: false,
  selectedTextTab: 'upcoming',
  initSelectedTabIndex: 0,
  scheduleFetchStatus: {
    isFetching: false,
    isError: false,
  },
  vodFetchStatus: {
    isFetching: false,
    isError: false,
  },
  mainEventDates: new Map(),
  lastVisitedDate: null,
  tooltipText: null,
};

export const state = () => Object.assign({}, initState);

export const getters = {
  scheduleListByDates: state => state.scheduleListByDates,
  filteredScheduleList: state => {
    if (
      !state.scheduleListByDates ||
      typeof state.scheduleListByDates !== 'object'
    ) {
      return {};
    }

    if (state.selectedTextTab === 'all') {
      return state.scheduleListByDates;
    } else if (state.selectedTextTab === 'VOD') {
      return state.vods;
    } else {
      // upcoming
      const startOfToday = startOfDay(state.todayDate);
      return Object.keys(state.scheduleListByDates)
        .filter(classDate => new Date(classDate) > startOfToday)
        .reduce(
          (res, key) =>
            Object.assign(res, {
              [key]: state.scheduleListByDates[key],
            }),
          {},
        );
    }
  },
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  todayDate: state => state.todayDate,
  isPastCalendar: state => state.isPastCalendar,
  selectedTextTab: state => state.selectedTextTab,
  initSelectedTabIndex: state => state.initSelectedTabIndex,
  scheduleFetchStatus: state => state.scheduleFetchStatus,
  mainEventDates: state => state.mainEventDates,
  lastVisitedDate: state => state.lastVisitedDate,
  tooltipText: state => state.tooltipText,
  vodFetchStatus: state => state.vodFetchStatus,
};

export const actions = {
  // startDate와 endDate 사이의 유저 스케줄을 날짜별로 묶어서 받아오는 api
  getUserScheduleByDates({ state, commit }, { startDate, endDate }) {
    // fetching  상태라면 호출하지 않게
    if (state.scheduleFetchStatus.isFetching) return;

    commit('SET_SCHEDULE_FETCH_STATUS', {
      isFetching: true,
      isError: false,
    });
    commit('RESET_MAIN_EVENT_DATES');
    commit('RESET_SCHEDULE_LIST');

    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/schedule`, {
          params: {
            startDate,
            endDate,
          },
        })
        .then(res => {
          commit('SET_SCHEDULE', {
            scheduleListByDates: res,
            startDate,
            endDate,
          });
          commit('SET_TODAY_DATE');
          commit('SET_CALENDAR_DATA');
          commit('SET_MAIN_EVENT_DATES');
          commit('SET_SCHEDULE_FETCH_STATUS', { isFetching: false });

          resolve(res);
        })
        .catch(e => {
          commit('SET_SCHEDULE_FETCH_STATUS', {
            isFetching: false,
            isError: true,
          });
          console.error(e);
          reject(e);
        });
    });
  },

  // 임시 API
  async getUserPurchasedVod() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/order/vod/purchased`,
        {
          params: {
            page: 1,
            size: 100,
          },
        },
      );

      const watchable = data.items
        .filter(
          i => new Date(i.viewableUntil).getTime() >= new Date().getTime(),
        )
        .map(i => {
          i.expired = false;
          return i;
        });
      const expired = data.items
        .filter(i => new Date(i.viewableUntil).getTime() < new Date().getTime())
        .map(i => {
          i.expired = true;
          return i;
        });

      const vodList = [...watchable, ...expired];

      return vodList;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  getUserPurchasedVodList({ state, commit }) {
    if (state.vodFetchStatus.isFetching) return;

    commit('SET_VOD_FETCH_STATUS', {
      isFetching: true,
      isError: false,
    });

    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/vod/purchased`, {
          params: {
            page: 1,
            size: 100,
          },
        })
        .then(res => {
          const watchable = res.data.items
            .filter(
              i => new Date(i.viewableUntil).getTime() >= new Date().getTime(),
            )
            .map(i => {
              i.expired = false;
              return i;
            });
          const expired = res.data.items
            .filter(
              i => new Date(i.viewableUntil).getTime() < new Date().getTime(),
            )
            .map(i => {
              i.expired = true;
              return i;
            });
          const vodList = [...watchable, ...expired];

          commit('SET_PURCHASED_VOD_LIST', vodList);

          commit('SET_VOD_FETCH_STATUS', {
            isFetching: false,
            isError: false,
          });

          resolve(vodList);
        })
        .catch(e => {
          commit('SET_VOD_FETCH_STATUS', {
            isFetching: false,
            isError: true,
          });
          console.error(e);
          reject(e);
        });
    });
  },
};

export const mutations = {
  RESET_CALENDAR(state) {
    Object.assign(state, initState);
  },
  RESET_SCHEDULE_LIST(state) {
    state.scheduleListByDates = [];
  },
  SET_SCHEDULE_FETCH_STATUS(state, fetchStatus) {
    state.scheduleFetchStatus = {
      ...state.scheduleFetchStatus,
      ...fetchStatus,
    };
  },
  SET_VOD_FETCH_STATUS(state, fetchStatus) {
    state.vodFetchStatus = {
      ...state.vodFetchStatus,
      ...fetchStatus,
    };
  },
  SET_PURCHASED_VOD_LIST(state, { items }) {
    state.vods = items;
  },
  SET_SCHEDULE(state, { scheduleListByDates, startDate, endDate }) {
    state.scheduleListByDates = scheduleListByDates;
    state.startDate = startDate;
    state.endDate = endDate;
  },
  SET_TODAY_DATE(state) {
    state.todayDate = new Date();
  },
  RESET_MAIN_EVENT_DATES(state) {
    state.mainEventDates = new Map();
  },
  SET_MAIN_EVENT_DATES(state) {
    const mainEventDates = new Map();

    Object.entries(state.scheduleListByDates).forEach(scheduleList => {
      const [key, schedules] = scheduleList;

      mainEventDates.set(key, {
        isMainEvent: true,
        eventText: `총 ${schedules.length}개의 수업`,
      });
    });

    state.mainEventDates = mainEventDates;
  },
  SET_CALENDAR_DATA(state) {
    const monthStartDate = startOfMonth(new Date(state.startDate));
    const monthEndDate = endOfMonth(new Date(state.endDate));
    if (state.todayDate < monthStartDate) {
      state.selectedTextTab = 'upcoming';
      state.initSelectedTabIndex = 0;
      state.isPastCalendar = false;
    } else if (state.todayDate > monthEndDate) {
      state.selectedTextTab = 'all';
      state.initSelectedTabIndex = 1;
      state.isPastCalendar = true;
    } else {
      state.selectedTextTab = 'upcoming';
      state.initSelectedTabIndex = 0;
      state.isPastCalendar = false;
    }
  },
  SELECT_TAB(state, { tabValue, tabIndex }) {
    state.selectedTextTab = tabValue;
    state.initSelectedTabIndex = tabIndex;
  },
  SET_LAST_VISITED_DATE(state, { lastVisitedDate }) {
    state.lastVisitedDate = lastVisitedDate;
  },
  SET_EVENT_TOOLTIP_TEXT(state, { tooltipText }) {
    state.tooltipText = tooltipText;
  },
};
