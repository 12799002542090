import { convertClassType } from '@/utils/classType.js';
import { contentsRenderer } from '@/utils/contentRender';
import { PRODUCT_STATUS } from '@/utils/enums.js';
import { getCalculatedPrice } from '@/utils/priceUtils.js';

const initContentsRendered = [
  {
    order: 0,
    title: '수업을 소개해요.',
    key: ['description'],
    html: '',
    sub: [],
  },
];

const initState = () => {
  return {
    previewVideoUrl: null, // 미리보기 영상 url 주소
    courseCount: 0, // 이 수업에 엮여있는 로드맵의 수. 다른 api를 호출한 후 세팅됩니다.
    productId: -1, // 상품 아이디
    contentsRendered: initContentsRendered,
    description: '', // 상세 정보
    thumbnailImg: '', // 썸네일
    title: '', // 상품명
    rating: '0.0', // 리뷰 레이팅
    ratingCount: 0, // 리뷰 갯수
    // 리뷰
    reviews: {
      items: [],
      meta: {
        error: false,
        currentPage: 1,
        totalPages: 2,
        itemsPerPage: 3,
        itemCount: 0,
        totalItems: 0,
        ratingTotal: 0,
      },
    },
    contentsNew: {
      classMaterials: {}, // 제공되는 수업자료
      curr: [], // 커리큘럼
      preparations: [], // 준비물
      recomm: [], // 추천 & 비추천
    },
    handouts: [], // 제공되는 수업자료 (신)
    // 선생님 정보
    teacher: {
      id: 0,
      name: '',
      subTitle: '',
      profileImg: '',
      selfIntro: '',
      specialization: '',
      contractStatus: '',
      career: [],
      ratingCount: 0,
      level: undefined, // 선생님 등급
    },
    categories: [], // 카테고리
    classPrice: 0, // 기존 표기 가격
    discountRate: undefined,
    classType: undefined, // '원데이 클래스' or '꾸러미 클래스' or '롤링 클래스'
    summary: '', // 상품 요약
    productStatus: null, // 수업 상태
    classTime: 0, // 수업 시간
    classCount: 0, // 수업 회차 수
    basePrice: 0, // 상품 기본 가격
    discountedPrice: 0, // 할인 적용된 가격
    calculatedPrice: 0, // 할인 적용 + 다회차의 경우 회차별로 나눠져 계산된 별개의 가격
    classAge: '', // 권장 연령 x~x세 의 텍스트로 내려옴
    maxUser: 0, // 최대 참여 인원
    recordable: false, // 다시 보기 지원 여부
    shopUrl: '', // 준비물 링크
    isUse: true, // 사용중인 상품인가
    productContent: {}, // 현재는 유의사항이 들어있는 컬럼
    productTimeTables: [], // 구독 스케줄 타임테이블
    favoriteCount: 0, // 찜하기 카운트

    // 선택된 스케줄의 ID
    selectedScheduleId: -1,

    // 구매 가능한 수업인지 체크 Schedule.vue 컴포넌트에서 위로 올립니다.
    canPurchase: false,
    // 구매 가능 수업 여부 체크값을 api를 통해 확인하고 나면 true로.
    isSetPurchase: false,

    // 이 부분은 스케줄 없음 영역이 보일 때 변경해줍니다.
    hasSchedule: true,
    isFull: false,

    // player 상태와 높이
    isPlayerPlaying: false,
    playerHeight: 0,

    // VOD 인지 체크
    isVod: false
  };
};

export const state = () => initState();

export const getters = {
  previewVideoUrl: state => state.previewVideoUrl,
  isPlayerPlaying: state => state.isPlayerPlaying,
  playerHeight: state => state.playerHeight,
  courseCount: state => state.courseCount,
  isSurvey: state => state.productStatus === PRODUCT_STATUS.SURVEY,
  isShowProductSubData: state => {
    // 초안 / 반려 / 승인대기 / 수요조사 상태일 때 특정 필드가 보이지 않을 수 있게 false로 내려줍니다
    const { DRAFT, REJECT, APPROVAL, SURVEY } = PRODUCT_STATUS;
    return ![DRAFT, REJECT, APPROVAL, SURVEY].includes(state.productStatus);
  },
  productId: state => parseInt(state.productId),
  description: state => state.description,
  thumbnailImg: state => state.thumbnailImg,
  classPrice: state => state.classPrice,
  title: state => state.title,
  discountRate: state => state.discountRate,
  contentsNew: state => {
    return {
      classMaterials: state.contentsNew?.classMaterials || {}, // 제공되는 수업자료
      curr: state.contentsNew?.curr || [], // 커리큘럼
      handouts: state.contentsNew?.handouts || [], // 제공되는 수업자료
      preparations: state.contentsNew?.preparations || [], // 준비물
      recomm: state.contentsNew?.recomm || [], // 추천 & 비추천
    };
  },
  handouts: state => state.handouts,
  classType: state => state.classType,
  summary: state => state.summary,
  teacher: state => state.teacher,
  categories: state => state.categories,
  reviews: state => state.reviews,
  rating: state => state.rating,
  ratingCount: state => state.ratingCount,
  productStatus: state => state.productStatus,
  classTime: state => state.classTime,
  classCount: state => state.classCount,
  convertedClassType: state => convertClassType(state.classType),
  basePrice: state => state.basePrice, // 상품 기본 가격
  discountedPrice: state => state.discountedPrice, // 할인 적용된 가격
  calculatedPrice: state => state.calculatedPrice, // 할인 적용 + 다회차의 경우 회차별로 나눠져 계산된 별개의 가격
  classAge: state => state.classAge,
  maxUser: state => state.maxUser,
  recordable: state => state.recordable, // 다시 보기 지원 여부
  shopUrl: state => state.shopUrl,
  isUse: state => state.isUse,
  // 메인 카테고리 id 목록 (로깅용)
  mainCategoryIds: state =>
    state.categories.reduce((acc, cur) => {
      if (cur?.parent.id) acc = [...acc, cur.parent.id];
      return acc;
    }, []),
  // 메인 카테고리 name 목록 (로깅용)
  mainCategoryNames: state =>
    state.categories.reduce((acc, cur) => {
      if (cur?.parent.name) acc = [...acc, cur.parent.name];
      return acc;
    }, []),
  // 서브 카테고리 id 목록 (로깅용)
  subCategoryIds: state =>
    state.categories.reduce((acc, cur) => {
      if (cur.id) acc = [...acc, cur.id];
      return acc;
    }, []),
  // 서브 카테고리 name 목록 (로깅용)
  subCategoryNames: state =>
    state.categories.reduce((acc, cur) => {
      if (cur.name) acc = [...acc, cur.name];
      return acc;
    }, []),
  productContent: state => state.productContent,
  // 검색용 쿼리에 쓰는 메인 카테고리 아이디
  mainCategoryIdSearchQuery: state => {
    if (state.categories.length === 0) return undefined;
    const [firstCategory] = state.categories;
    const topCategoryId = firstCategory?.parent?.id;

    if (!topCategoryId) return undefined;
    return topCategoryId;
  },
  // 검색용 쿼리에 쓰는 서브 카테고리 아이디
  subCategoryIdSearchQuery: state => {
    if (state.categories.length === 0) return undefined;
    const [firstCategory] = state.categories;
    const subCategoryId = String(firstCategory?.id);

    if (!subCategoryId) return undefined;
    return subCategoryId;
  },
  productTimeTables: state => state.productTimeTables.filter(t => t.isOpen),
  curriculum: state => state.contentsNew?.curr || [], // 구독 커리큘럼 정보
  selectedScheduleId: state => state.selectedScheduleId, // 선택한 스케줄 id (결제페이지로 보낼때 사용)
  favoriteCount: state => state.favoriteCount,
  canPurchase: state => state.canPurchase,
  isSetPurchase: state => state.isSetPurchase,
  // 찜하기 액션시 넣어줘야할 오브젝트
  favoriteObject: state => ({
    classId: state.productId,
    product: {
      id: state.productId,
      thumbnailImg: state.thumbnailImg,
      title: state.title,
      classType: state.classType,
      classAge: state.classAge,
      classPrice: state.classPrice,
      discountRate: state.discountRate,
      ratingCount: state.ratingCount,
      rating: state.rating,
      teacher: state.teacher,
      productStatus: state.productStatus,
      isFull: state.isFull,
      hasSchedule: state.hasSchedule,
    },
  }),
  isVod: state => state.isVod,
};

export const actions = {
  /**
   * @description 스토어 초기화
   */
  resetProduct({ commit }) {
    commit('RESET_PRODUCT_DETAIL');
  },
  /**
   * @description 상품 상세 정보와 몇가지 정보들을 가져오는 API
   */
  async getProductDetail({ commit }, { productId }) {
    try {
      const { product } = await this.$axios.$get(`/v2/product/${productId}`);
      const vodIdListResponse = await this.$axios.$get(`${process.env.API_V3}/v3/order/vod/class-id-only`);
      const isVod = vodIdListResponse.data.includes(productId);
      if (isVod) {
        product.isVod = true;
        product.classType = 'VOD';
        product.canPurchase = true;
      }

      commit('SET_PRODUCT_DETAIL', { product, productId });

      return product;
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * @description 상품에 달린 리뷰를 가져오는 API
   */
  async getProductReviews(
    { commit },
    { productId, page, limit, listType = 'RATING', append = true },
  ) {
    try {
      commit('SET_LOADING_REVIEW', { productId, limit, append });
      const res = await this.$axios.$get(`/v2/review/product/${productId}`, {
        params: {
          page,
          limit,
          listType,
        },
      });

      commit('SET_REVIEW', { res, productId, append });

      return res;
    } catch (e) {
      commit('SET_REVIEW_ERROR', { productId });
      console.error(e);
    }
  },
  /**
   * @description
   * 원데이 스케줄 기간 조회 api
   */
  async selectOnedaySchedule(_, { id, startDate, endDate }) {
    try {
      return await this.$axios.$get(`/v2/product/${id}/schedule/one_day`, {
        params: {
          startDate,
          endDate,
        },
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 다회차 스케줄 기간 조회 api
   */
  async selectProductGroupSchedule(_, { id, startDate, endDate }) {
    try {
      return await this.$axios.$get(`/v2/product/${id}/schedule/group`, {
        params: {
          startDate,
          endDate,
        },
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  RESET_PRODUCT_DETAIL(state) {
    Object.assign(state, initState());
  },
  SET_PRODUCT_DETAIL(state, { product, productId }) {
    Object.assign(state, product);

    const renderContents = contentsRenderer(
      product.contentsNew?.description || product.contents.description,
    );

    state.productId = parseInt(productId);
    state.description = renderContents;
    state.recommendProducts = product.recommendProducts;

    const { basePrice, discountedPrice, calculatedPrice } = getCalculatedPrice({
      classPrice: state.classPrice,
      discountRate: state.discountRate,
      classType: state.classType,
      classCount: state.classCount,
    });

    state.basePrice = basePrice;
    state.discountedPrice = discountedPrice;
    state.calculatedPrice = calculatedPrice;
  },
  SET_REVIEW(state, { res, productId, append }) {
    // 수비코드 / 불러온 리뷰와 현재 스토어의 상품 아이디가 다르면 세팅하지 않음
    if (state.productId !== productId) return;

    if (res.items) {
      if (append) {
        const filtered = state.reviews.items.filter(item => !item.isLoading);
        state.reviews.items = [...filtered, ...res.items];
      } else {
        state.reviews.items = [...res.items];
      }
    }
    Object.assign(state.reviews.meta, { ...res.meta, error: false });
  },
  SET_LOADING_REVIEW(state, { productId, limit, append }) {
    if (state.productId !== productId) return;

    state.reviews.meta.error = false;

    if (append) {
      state.reviews.items.push(...Array(limit).fill({ isLoading: true }));
    } else {
      state.reviews.items = Array(limit).fill({ isLoading: true });
    }
  },
  SET_REVIEW_ERROR(state, { productId }) {
    if (state.productId !== productId) return;

    state.reviews.meta.error = true;
  },
  SET_SELECTED_SCHEDULE(state, { scheduleId }) {
    state.selectedScheduleId = scheduleId;
  },
  SET_PRODUCT_FAVORITE_COUNT(state, { productId, favoriteCount }) {
    // api응답이 늦어져 다른 상품의 favoriteCount가 변경되는 경우를 방지합니다.
    if (state.id === productId) {
      state.favoriteCount = favoriteCount;
    }
  },
  SET_CAN_PURCHASE(state, { canPurchase }) {
    state.canPurchase = canPurchase;
    state.isSetPurchase = true; // true로 바꿔서 값 변경을 알려줍니다.
  },
  SET_COURSE_COUNT(state, { courseCount }) {
    state.courseCount = courseCount;
  },
  SET_IS_FULL(state, isFull) {
    state.isFull = isFull;
  },
  SET_HAS_SCHEDULE(state, hasSchedule) {
    state.hasSchedule = hasSchedule;
  },
  SET_PLAYER_STATUS(state, { isPlay, playerHeight }) {
    state.isPlayerPlaying = isPlay;
    state.playerHeight = playerHeight;
  },
};
