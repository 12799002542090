import { render, staticRenderFns } from "./ReviewComments.vue?vue&type=template&id=03255534&scoped=true"
import script from "./ReviewComments.vue?vue&type=script&lang=js"
export * from "./ReviewComments.vue?vue&type=script&lang=js"
import style0 from "./ReviewComments.vue?vue&type=style&index=0&id=03255534&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03255534",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewAdmin: require('/codebuild/output/src2988906559/src/components/review/ReviewAdmin.vue').default,ReviewTeacher: require('/codebuild/output/src2988906559/src/components/review/ReviewTeacher.vue').default})
