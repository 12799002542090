import { formatDate, makeYYMMDDHHMM } from '../../utils/dateFnsUtils';
import { PAY_METHOD, TOSS_ORDER_TYPE } from '../../utils/enums';
import { numberToCommaString } from '../../utils/numberString';
import { koPriceFormat } from '../../utils/string';

export const state = () => ({
  paymentDetail: null,
  paymentListObj: null,
  paymentList: [],
  serverTime: null,
});

export const getters = {
  isTargetClass: state =>
    state.paymentDetail?.orderType === TOSS_ORDER_TYPE.TARGET_CLASS,
  isEmptyPaymentList: state => state.paymentListObj?.empty,
  getPaymentListObj: state => state.paymentListObj,
  getPaymentList: state => state.paymentList,
  classInfo: state => state.paymentDetail?.classInfo,
  paymentClassInfo: state => {
    const { status, completedAt, classInfo = {} } = state.paymentDetail || {};
    return {
      status,
      completedAt,
      classInfo,
    };
  },
  childName: state => state.paymentDetail?.childName,
  subscribeId: state => state.paymentDetail?.subscribeId,
  /**
   * @returns CANCEL || COMPLETE || PART_CANCEL
   */
  orderStatus: state => state.paymentDetail?.status,
  scheduleList: state => {
    return (
      state.paymentDetail?.schedules?.map(({ id, startDateTime, ...rest }) => {
        const date = new Date(startDateTime.replace('+0900', ''));
        return {
          ...rest,
          id,
          startTime: startDateTime,
          startDateTime: makeYYMMDDHHMM(date),
        };
      }) || []
    );
  },
  classType: state => state.paymentDetail?.classInfo?.type,
  // 결제 정보
  // 결제 일시
  paymentDate: state => {
    if (!state.paymentDetail?.completedAt) return null;
    const completedAt = new Date(
      state.paymentDetail.completedAt.replace('+0900', ''),
    );
    return {
      main: {
        key: '결제 일시',
        value: formatDate(completedAt, 'yyyy. MM. dd. (eee) aaa hh:mm:ss'),
      },
    };
  },
  // 수업 가격 - 결제 당시의 수업 판매가
  classPrice: state => {
    if (
      !state.paymentDetail?.classInfo?.basePrice &&
      state.paymentDetail?.classInfo?.basePrice !== 0
    )
      return null;
    return {
      main: {
        key: '수업 가격',
        value: koPriceFormat(state.paymentDetail?.classInfo.basePrice),
      },
    };
  },
  // "쿠폰/할인"
  couponAndDiscount: state => {
    const { totalDiscountAmount, couponAmount, classDiscountAmount } =
      state.paymentDetail?.paymentInfo || {};

    if (!totalDiscountAmount && totalDiscountAmount !== 0) return null;
    const expansion = {
      main: {
        key: '쿠폰/할인',
        value: `-${koPriceFormat(totalDiscountAmount)}`,
      },
      subs: [],
    };

    if (couponAmount)
      expansion.subs = [
        ...expansion.subs,
        {
          key: '쿠폰',
          value: `-${numberToCommaString(couponAmount)}원`,
        },
      ];
    if (classDiscountAmount)
      expansion.subs = [
        ...expansion.subs,
        {
          key: '할인',
          value: `-${numberToCommaString(classDiscountAmount)}원`,
        },
      ];
    return expansion;
  },
  // 꾸그머니
  ggugeMoney: state => {
    const { totalMoneyAmount, rewardMoneyAmount, moneyAmount } =
      state.paymentDetail?.paymentInfo || {};

    // 사용한 토탈 꾸그머니가 없다면 null
    if (!totalMoneyAmount || totalMoneyAmount <= 0) return null;

    const expansion = {
      main: {
        key: '꾸그머니',
        value: `${numberToCommaString(totalMoneyAmount)}원`,
      },
    };

    // 금액이 있는 경우만, subs에 넣는다
    if (rewardMoneyAmount > 0) {
      expansion.subs = [
        {
          key: '혜택금액',
          value: `${numberToCommaString(rewardMoneyAmount)}원`,
        },
      ];
    }

    if (moneyAmount > 0) {
      const subs = expansion.subs || [];
      expansion.subs = [
        ...subs,
        {
          key: '충전금액',
          value: `${numberToCommaString(moneyAmount)}원`,
        },
      ];
    }
    return expansion;
  },
  // 결제 정보 > 결제 금액
  paymentAmount: state => {
    const { pgAmount, payMethod, cardName, cardNumber } =
      state.paymentDetail?.paymentInfo || {};

    // if (payMethod === 'MONEY' || payMethod === 'COUPON') return null;
    const expansionInfo = {
      main: {
        key: '결제금액',
        value:
          pgAmount === null && pgAmount === undefined
            ? '-'
            : `${numberToCommaString(pgAmount)}원`,
      },
    };

    if (payMethod !== 'FREE' && pgAmount === 0) return expansionInfo;
    expansionInfo.subs = [
      {
        key: PAY_METHOD[payMethod],
        value:
          payMethod === 'FREE'
            ? 'FREE'
            : `${cardName || '-'} (${cardNumber || '-'})`,
      },
    ];
    return expansionInfo;
  },
  // 결제 수단
  payMethod: state => {
    const { payMethod } = state.paymentDetail?.paymentInfo || {};

    if (!payMethod) return null;
    return PAY_METHOD[payMethod];
  },
  /**
   * @returns 카드이름(카드번호)
   */
  cardInfo: state => {
    const { cardName, cardNumber } = state.paymentDetail?.paymentInfo || {};
    if (!cardName && !cardNumber) return '-';
    return `${cardName || '-'}(${cardNumber || '-'})`;
  },
  // 총 결제 금액
  totalPaymentAmount: state =>
    state.paymentDetail?.paymentInfo?.totalPaymentAmount || 0,
  // 환불 정보 배열 반환
  orderCancels: state => state.paymentDetail?.orderCancels || [],
  // 총 환불금액
  totalRefundAmount: state => {
    if (!state.paymentDetail?.orderCancels) return 0;
    return state.paymentDetail?.orderCancels.reduce(
      (acc, { totalRefundAmount }) => acc + totalRefundAmount,
      0,
    );
  },
  receiptUrl: state => {
    const { receiptUrl } = state.paymentDetail?.paymentInfo || {};
    return receiptUrl;
  },
  // 응답 서버 타임
  serverTime: state => state.serverTime,
};

export const mutations = {
  setPaymentDetail(state, payload) {
    state.paymentDetail = payload;
  },
  setPaymentList(state, payload) {
    state.paymentList = payload;
  },
  setPaymentListObj(state, payload) {
    state.paymentListObj = payload;
  },
  reset(state) {
    state.paymentDetail = null;
    state.paymentListObj = null;
    state.paymentList = [];
    state.serverTime = null;
  },
  setServerTime(state, serverTime) {
    state.serverTime = serverTime;
  },
};

export const actions = {
  // 구매 및 환불 목록 조회 API
  async fetchUserPaymentList({ state }, { page, size = 20, orderStatus }) {
    const urlQuery = new URLSearchParams();
    urlQuery.set('page', page);
    urlQuery.set('size', size);
    orderStatus && urlQuery.set('orderStatus', orderStatus);
    try {
      const response = await this.$axios.$get(
        `${process.env.API_V3}/v3/order?${urlQuery.toString()}`,
      );
      const vodIdListResponse = await this.$axios.$get(`${process.env.API_V3}/v3/order/vod/class-id-only`);
      response.data.items = response.data.items.map((item) => {
        item.isVod = vodIdListResponse.data.includes(item.classInfo.id);
        if (item.isVod) {
          item.classInfo.type = 'VOD';
        }
        return item;
      })
      this.commit('user/payment/setPaymentListObj', response.data);
      this.commit('user/payment/setPaymentList', [
        ...state.paymentList,
        ...response.data.items,
      ]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // 구매 및 환불 상세 조회 API
  async fetchUserPaymentHistory(_, { orderId }) {
    try {
      const response = await this.$axios.$get(
        `${process.env.API_V3}/v3/order/${orderId}`,
      );
      const vodIdListResponse = await this.$axios.$get(`${process.env.API_V3}/v3/order/vod/class-id-only`);
      response.data.classInfo.isVod = vodIdListResponse.data.includes(response.data.classInfo.id);
      if (response.data.classInfo.isVod) {
        response.data.classInfo.type = 'VOD';
      }
      this.commit('user/payment/setPaymentDetail', null);
      this.commit('user/payment/setPaymentDetail', response.data);
      this.commit('user/payment/setServerTime', response.serverDatetime);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
