import { render, staticRenderFns } from "./FixedCuration.vue?vue&type=template&id=2af10a28&scoped=true"
import script from "./FixedCuration.vue?vue&type=script&lang=js"
export * from "./FixedCuration.vue?vue&type=script&lang=js"
import style0 from "./FixedCuration.vue?vue&type=style&index=0&id=2af10a28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af10a28",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCuration: require('/codebuild/output/src2988906559/src/components/product-curation/ProductCuration.vue').default})
