import { render, staticRenderFns } from "./ReviewItem.vue?vue&type=template&id=f95b3122&scoped=true"
import script from "./ReviewItem.vue?vue&type=script&lang=js"
export * from "./ReviewItem.vue?vue&type=script&lang=js"
import style0 from "./ReviewItem.vue?vue&type=style&index=0&id=f95b3122&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95b3122",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Review: require('/codebuild/output/src2988906559/src/components/review/Review.vue').default,ReviewComments: require('/codebuild/output/src2988906559/src/components/review/ReviewComments.vue').default,ReviewHelpful: require('/codebuild/output/src2988906559/src/components/review/ReviewHelpful.vue').default})
