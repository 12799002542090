export const getters = {
  vodIdList: state => state.vodIdList || [],
};

export const actions = {
  /**
   * @description 꾸그머니 충전을 위한 orderId를 발급받는 API
   * @param {number} payPgAmount 충전하려는 금액
   * @returns {string} orderUniqueId
   */
  createOrderChargeGgugeMoney(_, { payPgAmount }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/order/money`, {
          paymentInfo: {
            payPgAmount,
          },
        })
        .then(res => {
          resolve(res?.data?.orderUniqueId);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 토스에서 정상적으로 결제된 후 완료페이지로 redirect된 후
   * 혹은 꾸그머니, 0원결제로 완료페이지로 redirect된 후
   * 꾸그서버로 주문 결제 승인을 하는 API
   * @param {string} payMethod 충전하려는 수단
   * @param {string} orderUniqueId 유니크 아이디
   * @param {string} paymentKey 토스에서 온 경우 돌려받은 paymentKey
   */
  confirmOrder(_, { orderUniqueId, payMethod, paymentKey}) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/order/confirm`, {
          orderUniqueId,
          payMethod,
          paymentKey,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   *  주문: 수업 구독하기
   * @param {number} timeTableId  타임테이블 ID
   * @param {number} childrenId 아이 ID
   * @param {number} userCardId 유저 카드 ID
   * @param {number} userCouponId 유저 쿠폰 ID
   * @param {number} payPgAmount  PG 결제금액
   * @param {number} payMoneyAmount 꾸그 충전금액
   * @param {number} payRewardMoneyAmount 꾸그 혜택금액
   * @param {boolean} agreeEvent 기부 이벤트 동의 여부
   */
  subscribeOrder(
    _,
    {
      timeTableId,
      childrenId,
      userCardId,
      userCouponId,
      payPgAmount,
      payMoneyAmount,
      payRewardMoneyAmount,
      agreeEvent,
    },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/order/subscribe`, {
          timeTableId,
          childrenId,
          userCardId,
          userCouponId,
          paymentInfo: {
            payPgAmount,
            payMoneyAmount,
            payRewardMoneyAmount,
          },
          agreeEvent,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 유저용 주문 취소 사유 리스트
   */
  getOrderCancelReasonList() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/cancel-reason`, {
          params: { actorType: 'USER' },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 주문 상세 조회 API
   * @param {string} orderId
   */
  getOrderDetail(_, { orderId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/${orderId}`)
        .then(async res => {
          const vodIdListResponse = await this.$axios.$get(
            `${process.env.API_V3}/v3/order/vod/class-id-only`,
          );
          res.data.classInfo.isVod = vodIdListResponse.data.includes(
            res.data.classInfo.id,
          );
          if (res.data.classInfo.isVod === true) {
            res.data.classInfo.type = 'VOD';
          }
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 해당 order건이 환불시 얼마를 받고 페널티가 있는지 등을 조회하는 API
   * @param {string} orderUniqueId order조회 후 오는 유니크값
   */
  getOrderRefundData(_, { orderUniqueId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/${orderUniqueId}/refund`)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 원데이/다회차 주문 취소 API
   * @param {string} orderUniqueId order조회 후 오는 유니크값
   * @param {string} cancelCode 주문 취소 사유 CODE
   * @param {string} cancelReason 기타 사유시 추가
   */
  cancelOrder(_, { orderUniqueId, cancelCode, cancelReason }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/order/${orderUniqueId}/cancel`, {
          cancelCode,
          cancelReason,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 구독 주문 취소 API
   * @param {string} subscribeId order조회 후 오는 유니크값
   * @param {string} cancelCode 주문 취소 사유 CODE
   * @param {string} cancelReason 기타 사유시 추가
   */
  refundSubscribe(_, { subscribeId, cancelCode, cancelReason }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/refund`,
          {
            cancelCode,
            cancelReason,
          },
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },

  getPsyExeLink(_, { orderId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/${orderId}/execution`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  getScheduleIdByClassId(_, { classId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/vod/${classId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  getVodDetailsByScheduleId(_, scheduleId) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/vod/details`, {
          params: { scheduleId },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
};
