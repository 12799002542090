import { render, staticRenderFns } from "./CourseItem.vue?vue&type=template&id=2dbc7c02&scoped=true"
import script from "./CourseItem.vue?vue&type=script&lang=js"
export * from "./CourseItem.vue?vue&type=script&lang=js"
import style0 from "./CourseItem.vue?vue&type=style&index=0&id=2dbc7c02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbc7c02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CourseTitle: require('/codebuild/output/src2988906559/src/components/course/course-item/CourseTitle.vue').default,CourseCurriculum: require('/codebuild/output/src2988906559/src/components/course/course-item/CourseCurriculum.vue').default})
