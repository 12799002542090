import { render, staticRenderFns } from "./RatingAndFavorite.vue?vue&type=template&id=47111b78&scoped=true"
import script from "./RatingAndFavorite.vue?vue&type=script&lang=js"
export * from "./RatingAndFavorite.vue?vue&type=script&lang=js"
import style0 from "./RatingAndFavorite.vue?vue&type=style&index=0&id=47111b78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47111b78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonRating: require('/codebuild/output/src2988906559/src/components/common/CommonRating.vue').default,CommonLikeCount: require('/codebuild/output/src2988906559/src/components/common/CommonLikeCount.vue').default})
