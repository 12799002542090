import { addMilliseconds, isAfter } from 'date-fns';

const _CASH_TIME = 10 * 60 * 1000; // 10분
const _checkConfigValid = async ({ dispatch, state }) => {
  // time이 없다면 true, 아니라면 캐싱시간을 지났는지 체크해서 true | false를 던져줍니다.
  const _checkIsOverTime = time =>
    !time ? true : isAfter(new Date(), addMilliseconds(time, _CASH_TIME));

  // 캐싱 시간이 지났다면 remoteConfig api를 호출
  if (_checkIsOverTime(state.remoteConfigCallTime))
    await dispatch('getRemoteConfig');
};

/**
 * @description
 * https://www.notion.so/glorang/firebase-remote-config-c042efb920c346efbb5cedae348a3f6b
 * 키즈에서는 *_policy 와 web_drawer_banner_text_* 와 my_benefit_text 만 사용
 */
export const state = () => ({
  remoteConfig: {},
  remoteConfigCallTime: null,

  policyConfig: {},
});

export const getters = {
  drawerText: ({ remoteConfig }) => ({
    top: remoteConfig?.web_drawer_banner_text_top || null,
    bottom: remoteConfig?.web_drawer_banner_text_bot || null,
  }),
  friendsInviteBenefit: ({ remoteConfig }) => ({
    benefitText: remoteConfig?.my_benefit_text || null,
  }),
  // 아래 2개의 getters는 신규
  classTypePolicyConfig: ({ policyConfig }) => ({
    '원데이 클래스': policyConfig?.oneday || null,
    '다회차 클래스': policyConfig?.bundle || null,
    '구독 클래스': policyConfig?.subscribe || null,
  }),
  deliveryPolicyConfig: ({ policyConfig }) => ({
    deliveryPolicy: policyConfig?.delivery || null,
  }),
  // 아래는 플레어레인 팝업 타겟
  homeTalentPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.home_talent || '';
    return `homeTalent${suffix}`;
  },
  homeEducationPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.home_education || '';
    return `homeEducation${suffix}`;
  },
  productPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.product || '';
    return `product${suffix}`;
  },
  // paymentPopup: ({ remoteConfig }) => {
  //   const suffix = remoteConfig?.flarelane?.payment || '';
  //   return `payment${suffix}`;
  // },
  eventPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.event || '';
    return `event${suffix}`;
  },
  teacherPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.teacher || '';
    return `teacher${suffix}`;
  },
  roadmapPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.roadmap || '';
    return `roadmap${suffix}`;
  },
  signupPopup: ({ remoteConfig }) => {
    const suffix = remoteConfig?.flarelane?.signup || '';
    return `signup${suffix}`;
  },
};

export const actions = {
  /**
   * @description
   * 파이어베이스에서 config를 받아와 설정 & 부른 시간을 저장해 캐싱에 사용
   */
  async getRemoteConfig({ commit }) {
    try {
      const res = await this.$axios.$get(
        `${window.location.origin}/api/firebase-admin/config`,
      );
      commit('SET_REMOTE_CONFIG', {
        remoteConfig: res?.remoteConfig?.defaultValue?.value,
        policyConfig: res?.policyConfig?.defaultValue?.value,
        time: new Date(),
      });
      return;
    } catch (e) {
      console.error(e);
    }
  },
  async getDoodles(_) {
    try {
      const res = await this.$axios.$get(
        `${window.location.origin}/api/firebase-admin/doodles`,
      );
      return res;
    } catch (e) {
      console.error(e);
    }
  },
  // 초기 로딩시 한 번 호출용
  initConfig({ dispatch, state }) {
    _checkConfigValid({ dispatch, state });
  },
  // 햄버거메뉴 drawer 배너에 쓰이는 친구초대 혜택 문구
  async getDrawerBannerText({ dispatch, state, getters }) {
    await _checkConfigValid({ dispatch, state });
    return getters.drawerText;
  },
  // 내가 초대한 친구 목록에서 보여주는 혜택 문구
  async getFriendsInviteBenefit({ dispatch, state, getters }) {
    await _checkConfigValid({ dispatch, state });
    return getters.friendsInviteBenefit;
  },

  // 신규 2종
  // 수업 상세페이지 클래스 타입별 정책
  async getClassTypePolicyList({ dispatch, state, getters }) {
    await _checkConfigValid({ dispatch, state });
    return getters.classTypePolicyConfig;
  },
  // 배송 상품 정책
  async getDeliveryPolicyList({ dispatch, state, getters }) {
    await _checkConfigValid({ dispatch, state });
    return getters.deliveryPolicyConfig;
  },
};

export const mutations = {
  SET_REMOTE_CONFIG: (state, { remoteConfig, policyConfig, time }) => {
    state.remoteConfig = JSON.parse(remoteConfig);
    state.policyConfig = JSON.parse(policyConfig);
    state.remoteConfigCallTime = time;
  },
};
