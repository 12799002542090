/**
 * @description
 * 구, 신규 classType 데이터를 코드 전반에 사용하는 규격에 맞게
 * 컨버팅해주는 유틸 함수
 *
 * @param {String} classType
 * @returns '원데이 클래스' | '다화치 클래스' | '구독 클래스' | classType
 */
const convertClassType = classType => {
  switch (classType) {
    case '원데이 클래스':
    case 'ONEDAY':
    case '원데이 수업':
      return '원데이 클래스';
    case '꾸러미 클래스':
    case '다회차 클래스':
    case '다회차 수업':
    case 'BUNDLE':
      return '다회차 클래스';
    case '롤링 클래스':
    case '구독 클래스':
    case '구독 수업':
    case 'SUBSCRIBE':
      return '구독 클래스';
    case 'VOD':
      return 'VOD 클래스';
    default:
      return classType;
  }
};

/**
 * @description
 * 이벤트에 찍힐 class_type 프로퍼티 컨버트 함수
 *
 * @param {*} classType
 * @returns '원데이' | '다회차' | '구독' | classType(원본)
 */
const convertAmplitudeClassType = classType => {
  switch (classType) {
    case '원데이 클래스':
    case '원데이 수업':
    case 'ONEDAY':
      return '원데이';
    case '꾸러미 클래스':
    case '다회차 클래스':
    case '다회차 수업':
    case 'BUNDLE':
      return '다회차';
    case '롤링 클래스':
    case '구독 클래스':
    case '구독 수업':
    case 'SUBSCRIBE':
      return '구독';
    default:
      return classType;
  }
};

const classTypeEnum = {
  ONEDAY: '원데이 클래스',
  BUNDLE: '다회차 클래스',
  SUBSCRIBE: '구독 클래스',
  VOD: 'VOD 클래스',
};

Object.freeze(classTypeEnum);

export { classTypeEnum, convertAmplitudeClassType, convertClassType };
