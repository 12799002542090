/**
 * @description
 * tag 아이템 종류가 너무 많아서 정리하기 위한 파일
 */

const TagSet = {
  ALERT: {
    color: 'white',
    backgroundColor: '#e54e4c', //  $red-40
  },
  INFO: {
    color: '#495057', // $gray-70
    backgroundColor: '#f1f3f5', // $gray-10
  },
  PRIMARY: {
    color: '#264099', // $blue-60
    backgroundColor: '#e5ebff', // $blue-5
  },
  SUCCESS: {
    color: '#396a57', // $subscribe-text
    backgroundColor: '#d5f6e3', // $subscribe-bg
  },
  ONEDAY: {
    color: '#a3514e', // $oneday-text
    backgroundColor: '#fae3e2', // $oneday-bg
  },
  BUNDLE: {
    color: '#9d663f',
    backgroundColor: '#fcf2cc',
  },
  SUBSCRIBE: {
    color: '#417661',
    backgroundColor: '#d9f8e7',
  },
  SURVEY: {
    color: '#264099', // $blue-60
    backgroundColor: '#e5ebff', // $blue-5
  },
  TEACHER: {
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  ROADMAP: {
    color: 'white',
    backgroundColor: '#495057', // $gray-70
  },
  VOD: {
    color: '#E8590C',
    backgroundColor: '#ffe8cc',
  },
};

const TagEnums = {
  ALERT: 'ALERT',
  INFO: 'INFO',
  PRIMARY: 'PRIMARY',
  SUCCESS: 'SUCCESS',
  ONEDAY: 'ONEDAY',
  BUNDLE: 'BUNDLE',
  SUBSCRIBE: 'SUBSCRIBE',
  SURVEY: 'SURVEY',
  TEACHER: 'TEACHER',
  ROADMAP: 'ROADMAP',
  VOD: 'VOD',
};

const TagNames = Object.keys(TagSet);

export { TagEnums, TagNames, TagSet };
