import { renderContents } from '@/utils/contentRender';
import { getDateISOString } from '@/utils/dateString';
import intl from '@/utils/intl';

const initContentsRendered = [
  {
    order: 0,
    title: '수업을 소개해요.',
    key: ['description'],
    html: '',
    sub: [],
  },
];

const initState = () => {
  return {
    isSubscriptionSchedule: false,
    contentsRendered: initContentsRendered,
    product: {
      productContent: {}, // 유의사항
      contentsRendered: initContentsRendered,
      contentsNew: {
        classMaterials: {}, // 제공되는 수업자료
        curr: [], // 커리큘럼
        handouts: [], // 제공되는 수업자료
        preparations: [], // 준비물
        recomm: [], // 추천 & 비추천
      },
      isVod: false, // VOD인지 체크하는 타 api에서 주입시켜주는 값
    },
    handouts: [],
    timeTableId: null,
    userProducts: [],
    joinedCount: 0,
    maxCount: 0,
    isGroup: false,
    classTimeStart: '',
    classTimeEnd: '',
    meta: {},
    zoomInfo: {},
    scheduleId: 0,
    recordingId: null,
    classDates: [],
    classDate: '',
    title: '',
    createAt: '',
    hasReview: false,
  };
};

export const state = () => initState();

export const getters = {
  isSubscriptionSchedule: state => state.isSubscriptionSchedule,
  product: state => state.product,
  classType: state => state.product?.classType,
  handouts: state => state.handouts,
  curriculum: state =>
    state.product.contentsNew ? state.product?.contentsNew?.curr : [],
  preparations: state =>
    state.product.contentsNew ? state.product?.contentsNew?.preparations : [],
  productContent: state => state.product?.productContent || {},
  meta: state => state.meta || {},
  zoomInfo: state => state.zoomInfo,
  classCapacity: state => state.maxCount - state.joinedCount,
  // children 이름 배열 구독과 일반상품 규격 맞추기
  children: state =>
    state.userProducts.map(u => {
      return { id: u.childrenId, name: u.children.name };
    }),
  timeTableId: state => state.timeTableId,
  recordingId: state => state.recordingId,
  classDate: state => state.classDate,
  classDates: state => state.classDates,
  classTimeStart: state => state.classTimeStart,
  classTimeEnd: state => state.classTimeEnd,
  title: state => state.title,
  scheduleId: state => state.scheduleId,
  productTimeTable: state => state.productTimeTable,
  productId: state => state.product?.id,
  createAt: state => state.createAt,
  teacherInfo: state => state.product?.teacher,
  userProductId: state => {
    if (!state.userProducts || state.userProducts.length === 0) return null;
    return state.userProducts[0].id;
  },
  isClassDay: state => {
    const d = getDateISOString(intl.getKoreaToday());
    return d === state.classDate;
  },
  classStartTimeDate: ({ classDate, classTimeStart }) => {
    if (!classDate || !classTimeStart) return null;
    return new Date(`${classDate}T${classTimeStart}:00+09:00`);
  },
  classEndTimeDate: ({ classDate, classTimeEnd }) => {
    if (!classDate || !classTimeEnd) return null;
    return new Date(`${classDate}T${classTimeEnd}:00+09:00`);
  },
  hasReview: ({ hasReview }) => hasReview,
  isVod: ({ product }) => product.isVod,
};

export const actions = {
  resetHashProductDetail({ commit }) {
    commit('RESET_HASH_PRODUCT_DETAIL');
  },
  // 스케줄 상세 조회
  getScheduleDetail({ commit }, { hash }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/product/${hash}/detail`)
        .then(async res => {
          if (!res.error) {
            const vodIdListResponse = await this.$axios.$get(`${process.env.API_V3}/v3/order/vod/class-id-only`);
            res.data.product.isVod = vodIdListResponse.data.includes(res.data.product.id);
            commit('SET_HASH_PRODUCT_DETAIL', res.data);
          } else {
            console.error(res.data.message);
          }
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  // 해당 스케줄의 zoom 호스트 접속 여부
  getScheduleHostStatus(_, { hash }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/product/${hash}/classStatus`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 출석체크 & Zoom 방 생성(없다면)
  createZoomAndAttendance({ commit }, { scheduleId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/product/zoom/${scheduleId}`)
        .then(res => {
          commit('SET_ZOOM_JOIN_DATA', res.data);
          resolve(res.data);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_HASH_PRODUCT_DETAIL(state, res) {
    state.isSubscriptionSchedule = res.product.classType === '롤링 클래스';
    const renderedProduct = renderContents(res.product);
    Object.assign(state.product, renderedProduct);
    state.handouts = res.handouts || [];
    state.timeTableId = res.timeTableId || null;
    Object.assign(state.meta, res.meta || {});
    Object.assign(state.zoomInfo, res.zoomInfo || {});
    state.userProducts = res.userProducts || [];
    state.scheduleId = res.id;
    state.classDates = res.classDates || [];
    state.classDate = res.classDate || '';
    state.title = res.product.title;
    state.classTimeStart = res.classTimeStart || '';
    state.classTimeEnd = res.classTimeEnd || '';
    state.createAt = res.createAt || '';
    state.maxCount = res.maxCount || 0;
    state.joinedCount = res.joinedCount || 0;
    state.hasReview = res.hasReview || false;
    state.recordingId = res.recordingId || null;
  },
  RESET_HASH_PRODUCT_DETAIL(state) {
    Object.assign(state, initState());
  },
  SET_ZOOM_JOIN_DATA(state, { id, password, joinUrl }) {
    state.zoomInfo = { id, password, joinUrl };
  },
};
