
/**
 * @description
 * 홈 > 헤더 아래 붙는 키워드의 아이템
 */

import { CLICK_KEYWORD_BANNER } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';

export default {
  name: 'KeywordItem',
  props: {
    keyword: { type: String, default: null }, // 실제 검색에 쓰는 키워드
    title: { type: String, default: null }, // 표시에 쓰는 키워드
    icon: { type: String, default: null },
    sequence: { type: Number, default: 0 },
    isTalentPage: { type: Boolean, default: false },
    // 라우트 링크
    routeLink: { type: String, default: null },
  },
  computed: {
    routedLink() {
      return this.routeLink
        ? `${this.routeLink}${this.routeLink.includes('?') ? '&' : '?'}${this.routeLink.includes('/search') ? 'isDirect=true' : ''}`
        : { name: 'search', query: { keyword: this.title, isDirect: true } }
    }
  },
  methods: {
    onClickKeyword() {
      this.logUserCustomEvent(CLICK_KEYWORD_BANNER, {
        keyword: this.keyword,
        sequence: this.sequence || null,
        home_type: this.isTalentPage ? HOME_TYPE.TALENT : HOME_TYPE.EDUCATION,
      });
    },
  },
};
